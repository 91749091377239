*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  background-color: $color-dark-600;
}

body {
  font-family: Rubik, sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  box-sizing: border-box;
  color: $color-light;
  line-height: 1.2;
  min-height: 100vh;
  max-width: 60rem;
  padding: 8rem 3rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 62em) {
    & {
      padding: 3rem;
      max-width: 114rem;
    }
  }
}

p,
h1,
h2,
h3 {
  word-break: break-word;
}

h1,
h2,
h3 {
  font-weight: 500;
}

h3 {
  font-size: 1.8rem;
}


