.dashboard {
  width: 85vw;
  max-width: 60rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;

  @media (min-width: 62em) {
    & {
      max-width: 100rem;
      grid-template-columns: minmax(10vw, 25rem) 1fr;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    background-color: $color-dark;
    border-radius: $border-radius-card;
    overflow: hidden;
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 2em;
    background-color: $color-purple;
    border-radius: $border-radius-card;

    @media (min-width: 62em) {
      & {
        flex-grow: 1;
        flex-direction: column;
        align-items: flex-start;
        gap: 4rem;
      }
    }
  }

  &__img {
    width: 6rem;
    max-width: 100%;
    border: 0.4rem solid $color-light;
    border-radius: 50%;

    @media (min-width: 48em) {
      & {
        width: 8rem;
      }
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @media (min-width: 62em) {
      & {
        gap: 1rem;
      }
    }
  }

  &__title {
    font-size: calc(0.5rem + 2.2vw);
    font-weight: 400;
    color: $color-light-500;

    @media (min-width: 48em) {
      & {
        font-size: 1.8rem;
      }
    }
  }

  &__name {
    font-weight: 300;
    font-size: calc(0.5rem + 4.2vw);

    @media (min-width: 48em) {
      & {
        font-size: 3.8rem;
      }
    }
  }

  &__period {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding: 1.4em 2em;

    @media (min-width: 62em) {
      & {
        flex-grow: 1;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 2em;
      }
    }
  }

  &__period-item {
    font-size: 1.6rem;
    font-weight: 400;
    color: $color-light-600;
    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.5);
    transition: color 0.2s;
  }

  &__period-radio {
    display: none;

    &:checked + * {
      color: $color-light;
    }
  }

  &__main {
    list-style-type: none;
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;

    @media (min-width: 62em) {
      & {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: $border-radius-card;
    overflow: hidden;

    &--loading {

      .dashboard__report{
          grid-template-columns: 1fr 2fr;

          @media (min-width: 62em) {
            & {
              grid-template-columns: 1fr auto;
            }
          }
        
          
      }
      .dashboard__time,
      .dashboard__previous {
        background-color: $color-dark;
        background-image: linear-gradient(
          110deg,
          $color-dark-600 8%,
          $color-dark 18%,
          $color-dark-600 33%
        );
        height: 4rem;
        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
      }
      .dashboard__previous *{
          visibility: hidden;
      }
    }

    &--work {
      background-color: $color-work;
    }

    &--play {
      background-color: $color-play;
    }

    &--study {
      background-color: $color-study;
    }

    &--exercise {
      background-color: $color-exercise;
    }

    &--social {
      background-color: $color-social;
    }

    &--self-care {
      background-color: $color-self-color;
    }
  }

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }

  &__icon {
    position: absolute;
    left: 100%;
    top: -0.8rem;
    transform: translateX(calc(-1 * (100% + 1rem)));
    width: 6rem;
  }

  &__report {
    position: relative;
    z-index: 10;
    background-color: $color-dark;
    margin-top: 4rem;
    border-radius: $border-radius-card $border-radius-card 0 0;
    padding: 2em;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0.5rem 1rem;
    align-items: center;

    @media (min-width: 62em) {
      & {
        align-items: start;
        grid-template-columns: 1fr auto;
      }
    }
  }

  &__time {
    font-size: 2.8rem;
    font-weight: 300;

    @media (min-width: 62em) {
      & {
        grid-column: 1 / 3;
        padding-top: 2rem;
      }
    }
  }

  &__menu-icon-container {
    text-align: right;
  }

  &__menu-icon {
    width: 2rem;
  }

  &__previous {
    font-size: 1.4rem;
    color: $color-light-500;
    text-align: right;
    padding-top: 0.5rem;

    @media (min-width: 62em) {
      & {
        grid-column: 1 / 3;
        text-align: left;
      }
    }
  }
}
