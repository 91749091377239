// COLORS
$color-dark: hsl(236, 45%, 20%);
$color-dark-600: hsl(226, 43%, 10%);
$color-light: #ffffff;
$color-light-500: hsl(236, 100%, 87%);
$color-light-600: hsl(235, 45%, 61%);

$color-purple: #5647ea;

$color-work: hsl(15, 100%, 70%);
$color-play: hsl(195, 74%, 62%);
$color-study: hsl(348, 100%, 68%);
$color-exercise: hsl(145, 58%, 55%);
$color-social: hsl(264, 64%, 52%);
$color-self-color: hsl(43, 84%, 65%);

$border-radius-card: 1.5rem;
