@font-face {
  font-family: 'Rubik';
  src: url('../fonts/Rubik-Light.woff2') format('woff2'),
  url('../fonts/Rubik-Light.woff') format('woff'),
  url('../fonts/Rubik-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('../fonts/Rubik-Regular.woff2') format('woff2'),
  url('../fonts/Rubik-Regular.woff') format('woff'),
  url('../fonts/Rubik-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('../fonts/Rubik-Medium.woff2') format('woff2'),
  url('../fonts/Rubik-Medium.woff') format('woff'),
  url('../fonts/Rubik-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
